import React, { useState } from "react";
import { Field, reduxForm } from "redux-form/immutable";
import renderTextField from "../../../../../components/fields/renderTextField";
import IconWrapper from "../../../../icons/IconWrapper";
import SuccessIcon from "../../../../icons/misc/SuccessIcon";

const InsertLinkModal = ({ save, onCancel, ...props }) => {
	const [link, setLink] = useState("");

	const onSubmit = () => {
		save(link);
		onCancel();
	};

	const isValid = () => {
		return link.startsWith("https://") || link.startsWith("http://");
	};
	return (
		<div
			className={`form d-flex flex align-items-center w-100 flex-auto standardized-form mb-24`}
			//	onSubmit={props.handleSubmit(onSubmit)}
			// autoComplete="off"
			// noValidate
		>
			<Field
				name={`link`}
				id={`link`}
				placeholder={"https://..."}
				component={renderTextField}
				onKeyDown={(value) => setLink(value)}
				type="text"
				size={"w-100"}
				showPlaceholder={true}
			/>

			<button
				type="button"
				disabled={!isValid()}
				onClick={onSubmit}
				className="btn btn--icon btn--icon--solo btn--secondary ml-regular"
			>
				<IconWrapper
					wrapperClasses="mx-s"
					size="24"
					icon={<SuccessIcon iconClass="d-flex" />}
				/>
			</button>
		</div>
	);
};

export default reduxForm({
	form: "insertLinkForm",
	touchOnBlur: false,
	destroyOnUnmount: true,
	enableReinitialize: false,
})(InsertLinkModal);

import React, { memo, useState } from "react";
import { connect } from "react-redux";
import IconWrapper from "../../../shared/icons/IconWrapper";
import { sizeConstants } from "../../../constants/constants";
import { getComponentCategoryIcon } from "../../../utils/getTypeIcon";
import { componentCategoryLabelConstants } from "../../../utils/label.util";
import CustomAccordion from "../../CustomAccordion/CustomAccordion";
import LodValue from "./lod-content/LodValue";
import ComponentCategoryContent from "./ComponentCategoryContent";
import ComponentTooltip from "../../components/component-tooltip/ComponentTooltip";

const ComponentCategoryRow = ({
	editView = false,
	componentCategory,
	selectedLodValue,
	defaultSelectedLodValue = null,
	editable = false,
	isForPackage = false,
	handleChange,
	id,
	// From state
	projectLodValues,
}) => {
	const [expanded, setExpanded] = useState(false);
	const {
		id: componentCategoryId,
		type,
		// icondata
	} = componentCategory;

	const categoryType = !!componentCategoryLabelConstants[type]
		? componentCategoryLabelConstants[type]
		: type;

	if (!componentCategory) {
		return;
	}

	const handleClick = (lodValueId, lodValueType) => {
		handleChange && handleChange(componentCategoryId, lodValueId, lodValueType);
	};

	const renderAccordionTitle = () => {
		return (
			<div className="w-101 lod">
				<div className="d-flex w-101">
					<div className="col col-15 d-flex align-items-center pl-regular">
						{false && (
							<IconWrapper
								size={sizeConstants.MEDIUM}
								wrapperClasses="mr-24"
								icon={getComponentCategoryIcon(type)}
							/>
						)}
						<ComponentTooltip content={categoryType} direction={"right"}>
							<div className="text-overflow-ellipsis">{categoryType}</div>
						</ComponentTooltip>
					</div>
					<div className="col col-85 d-flex justify-content-between mr-0 ml-auto">
						{projectLodValues &&
							projectLodValues.valueSeq().map((value, i) => {
								return (
									<div
										key={i}
										className={`lod__value w-100 ${i === 0 ? "pl-xs" : ""}`}
									>
										<LodValue
											handleClick={handleClick}
											disabled={!editable}
											id={value.get("id")}
											label={value.get("type")}
											selected={value.get("id") === selectedLodValue?.lodId}
											defaultSelected={
												defaultSelectedLodValue
													? value.get("id") === defaultSelectedLodValue?.lodId
													: null
											}
										/>
									</div>
								);
							})}
					</div>
				</div>

				{/* <div className="col col-85  ">
				  <div className="row row--xs-gutters justify-content-between"> 
					{projectLodValues &&
						projectLodValues.valueSeq().map((value, i) => {
							return (
								// <div key={i} className={`col  ${i === 0 ? "col-5" : " "}  `}>
								<LodValue
									handleClick={handleClick}
									disabled={!editable}
									id={value.get("id")}
									label={value.get("type")}
									selected={value.get("id") === selectedLodValue?.lodId}
								/>
								// </div>
							);
						})}
					  </div>  
				</div> */}
			</div>
		);
	};

	return (
		<CustomAccordion
			onlyIcon={true}
			initialValue={expanded === id}
			wrapperClasses={`accordion--none w-100 category-content ${
				selectedLodValue?.lodId > 1 && !isForPackage
					? "category-content--active"
					: ""
			}`}
			titleContent={renderAccordionTitle()}
			customOnChange={(value) => {
				setExpanded(value === expanded && value ? false : id);
			}}
		>
			<div className="w-100">
				{expanded === id && (
					<ComponentCategoryContent
						editView={editView}
						contentId={id}
						selectedLodId={selectedLodValue?.lodId}
					/>
				)}
			</div>
		</CustomAccordion>
	);
};

const MapStateToProps = (state) => {
	return {
		projectLodValues: state.getIn(["administration", "lods"]),
	};
};

const arePropsEqual = (oldProps, newProps) => {
	if (
		oldProps.selectedLodValue?.lodId !== newProps.selectedLodValue?.lodId ||
		!oldProps?.projectLodValues
	) {
		return false;
	} else {
		return true;
	}
};
export default connect(
	MapStateToProps,
	{}
)(memo(ComponentCategoryRow, arePropsEqual));

import { $createCodeNode, $isCodeNode } from "@lexical/code";
import { $isLinkNode, TOGGLE_LINK_COMMAND } from "@lexical/link";
import {
	$isListNode,
	INSERT_ORDERED_LIST_COMMAND,
	INSERT_UNORDERED_LIST_COMMAND,
	ListNode,
} from "@lexical/list";
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import {
	$createHeadingNode,
	$createQuoteNode,
	$isHeadingNode,
} from "@lexical/rich-text";
import { $isParentElementRTL, $setBlocksType } from "@lexical/selection";
import {
	$findMatchingParent,
	$getNearestNodeOfType,
	mergeRegister,
} from "@lexical/utils";
import {
	$createParagraphNode,
	$getSelection,
	$isRangeSelection,
	$isRootOrShadowRoot,
	CAN_REDO_COMMAND,
	CAN_UNDO_COMMAND,
	COMMAND_PRIORITY_CRITICAL,
	createCommand,
	FORMAT_ELEMENT_COMMAND,
	FORMAT_TEXT_COMMAND,
	INDENT_CONTENT_COMMAND,
	OUTDENT_CONTENT_COMMAND,
	REDO_COMMAND,
	SELECTION_CHANGE_COMMAND,
	UNDO_COMMAND,
} from "lexical";
import { useCallback, useEffect, useState } from "react";
import * as React from "react";
import { getSelectedNode } from "./utils/getSelectedNode";
import DropDown, { DropDownItem } from "./ui/DropDown";
import { INSERT_TABLE_COMMAND } from "@lexical/table";
import Modal from "../../../../components/Modal";
import TableModal from "./ui/TableModal";
import InsertImage from "./ui/InsertImage";
import InsertVideo from "./ui/InsertVideo";
import InsertLinkModal from "./ui/InsertLinkModal";

export const INSERT_IMAGE_COMMAND = createCommand("INSERT_IMAGE_COMMAND");
export const INSERT_VIDEO_COMMAND = createCommand("INSERT_VIDEO_COMMAND");

const blockTypeToBlockName = {
	bullet: "Bulleted List",
	check: "Check List",
	code: "Code Block",
	h1: "Heading 1",
	h2: "Heading 2",
	h3: "Heading 3",
	h4: "Heading 4",
	h5: "Heading 5",
	h6: "Heading 6",
	number: "Numbered List",
	paragraph: "Normal",
	quote: "Quote",
};

function dropDownActiveClass(active) {
	if (active) return "active dropdown-item-active";
	else return "";
}

function BlockFormatDropDown({
	editor,
	blockType,
	disabled = false,
	hideQuoteAndCode = false,
}) {
	const formatParagraph = () => {
		editor.update(() => {
			const selection = $getSelection();
			if ($isRangeSelection(selection)) {
				$setBlocksType(selection, () => $createParagraphNode());
			}
		});
	};

	const formatHeading = (headingSize) => {
		if (blockType !== headingSize) {
			editor.update(() => {
				const selection = $getSelection();
				$setBlocksType(selection, () => $createHeadingNode(headingSize));
			});
		}
	};

	const formatBulletList = () => {
		if (blockType !== "bullet") {
			editor.dispatchCommand(INSERT_UNORDERED_LIST_COMMAND, undefined);
		} else {
			formatParagraph();
		}
	};

	// const formatCheckList = () => {
	//   if (blockType !== "check") {
	//     editor.dispatchCommand(INSERT_CHECK_LIST_COMMAND, undefined);
	//   } else {
	//     editor.dispatchCommand(REMOVE_LIST_COMMAND, undefined);
	//   }
	// };

	const formatNumberedList = () => {
		if (blockType !== "number") {
			editor.dispatchCommand(INSERT_ORDERED_LIST_COMMAND, undefined);
		} else {
			formatParagraph();
		}
	};

	const formatQuote = () => {
		if (blockType !== "quote") {
			editor.update(() => {
				const selection = $getSelection();
				$setBlocksType(selection, () => $createQuoteNode());
			});
		}
	};

	const formatCode = () => {
		if (blockType !== "code") {
			editor.update(() => {
				let selection = $getSelection();

				if (selection !== null) {
					if (selection.isCollapsed()) {
						$setBlocksType(selection, () => $createCodeNode());
					} else {
						const textContent = selection.getTextContent();
						const codeNode = $createCodeNode();
						selection.insertNodes([codeNode]);
						selection = $getSelection();
						if ($isRangeSelection(selection)) {
							selection.insertRawText(textContent);
						}
					}
				}
			});
		}
	};

	return (
		<DropDown
			disabled={disabled}
			buttonClassName="toolbar-item block-controls"
			buttonIconClassName={"LexicalEditor__icon block-type " + blockType}
			buttonLabel={blockTypeToBlockName[blockType]}
			buttonAriaLabel="Formatting options for text style"
		>
			<DropDownItem
				className={"item " + dropDownActiveClass(blockType === "paragraph")}
				onClick={formatParagraph}
			>
				<i className="LexicalEditor__icon paragraph" />
				<span className="LexicalEditor__text text">Normal</span>
			</DropDownItem>
			<DropDownItem
				className={"item " + dropDownActiveClass(blockType === "h1")}
				onClick={() => formatHeading("h1")}
			>
				<i className="LexicalEditor__icon h1" />
				<span className="LexicalEditor__text text">Heading 1</span>
			</DropDownItem>
			<DropDownItem
				className={"item " + dropDownActiveClass(blockType === "h2")}
				onClick={() => formatHeading("h2")}
			>
				<i className="LexicalEditor__icon h2" />
				<span className="LexicalEditor__text text">Heading 2</span>
			</DropDownItem>
			<DropDownItem
				className={"item " + dropDownActiveClass(blockType === "h3")}
				onClick={() => formatHeading("h3")}
			>
				<i className="LexicalEditor__icon h3" />
				<span className="LexicalEditor__text text">Heading 3</span>
			</DropDownItem>
			<DropDownItem
				className={"item " + dropDownActiveClass(blockType === "h4")}
				onClick={() => formatHeading("h4")}
			>
				<i className="LexicalEditor__icon h4" />
				<span className="LexicalEditor__text text">Heading 4</span>
			</DropDownItem>
			<Divider />
			<DropDownItem
				className={"item " + dropDownActiveClass(blockType === "bullet")}
				onClick={formatBulletList}
			>
				<i className="LexicalEditor__icon bullet-list" />
				<span className="LexicalEditor__text text">Bullet List</span>
			</DropDownItem>
			<DropDownItem
				className={"item " + dropDownActiveClass(blockType === "number")}
				onClick={formatNumberedList}
			>
				<i className="LexicalEditor__icon numbered-list" />
				<span className="LexicalEditor__text text">Numbered List</span>
			</DropDownItem>
			{!hideQuoteAndCode && (
				<>
					<Divider />
					<DropDownItem
						className={"item " + dropDownActiveClass(blockType === "quote")}
						onClick={formatQuote}
					>
						<i className="LexicalEditor__icon quote" />
						<span className="LexicalEditor__text text">Quote</span>
					</DropDownItem>
					<DropDownItem
						className={"item " + dropDownActiveClass(blockType === "code")}
						onClick={formatCode}
					>
						<i className="LexicalEditor__icon code" />
						<span className="LexicalEditor__text text">Code Block</span>
					</DropDownItem>
				</>
			)}
		</DropDown>
	);
}

function Divider() {
	return <div className="divider" />;
}

const Toolbar = ({ hideTableAndImage, hideQuoteAndCode }) => {
	const [editor] = useLexicalComposerContext();
	const [activeEditor, setActiveEditor] = useState(editor);
	const [blockType, setBlockType] = useState("paragraph");
	const [insertLinkModal, setInsertLinkModal] = useState(false);
	const [showModal, setShowModal] = useState(false);
	const [isLink, setIsLink] = useState(false);
	const [isBold, setIsBold] = useState(false);
	const [isItalic, setIsItalic] = useState(false);
	const [isUnderline, setIsUnderline] = useState(false);

	const [canUndo, setCanUndo] = useState(false);
	const [canRedo, setCanRedo] = useState(false);

	const [isRTL, setIsRTL] = useState(false);

	const [isEditable, setIsEditable] = useState(() => editor.isEditable());

	const updateToolbar = useCallback(() => {
		const selection = $getSelection();
		if ($isRangeSelection(selection)) {
			const anchorNode = selection.anchor.getNode();
			let element =
				anchorNode.getKey() === "root"
					? anchorNode
					: $findMatchingParent(anchorNode, (e) => {
							const parent = e.getParent();
							return parent !== null && $isRootOrShadowRoot(parent);
						});

			if (element === null) {
				element = anchorNode.getTopLevelElementOrThrow();
			}

			const elementKey = element.getKey();
			const elementDOM = activeEditor.getElementByKey(elementKey);

			// Update text format
			setIsBold(selection.hasFormat("bold"));
			setIsItalic(selection.hasFormat("italic"));
			setIsUnderline(selection.hasFormat("underline"));

			setIsRTL($isParentElementRTL(selection));

			// Update links
			const node = getSelectedNode(selection);
			const parent = node.getParent();
			if ($isLinkNode(parent) || $isLinkNode(node)) {
				setIsLink(true);
			} else {
				setIsLink(false);
			}

			if (elementDOM !== null) {
				if ($isListNode(element)) {
					const parentList = $getNearestNodeOfType(anchorNode, ListNode);
					const type = parentList
						? parentList.getListType()
						: element.getListType();
					setBlockType(type);
				} else {
					const type = $isHeadingNode(element)
						? element.getTag()
						: element.getType();
					if (type in blockTypeToBlockName) {
						setBlockType(type);
					}
					if ($isCodeNode(element)) {
						return;
					}
				}
			}
			// Handle buttons
		}
	}, [activeEditor]);

	useEffect(() => {
		return editor.registerCommand(
			SELECTION_CHANGE_COMMAND,
			(_payload, newEditor) => {
				updateToolbar();
				setActiveEditor(newEditor);
				return false;
			},
			COMMAND_PRIORITY_CRITICAL
		);
	}, [editor, updateToolbar]);

	useEffect(() => {
		return mergeRegister(
			editor.registerEditableListener((editable) => {
				setIsEditable(editable);
			}),
			activeEditor.registerUpdateListener(({ editorState }) => {
				editorState.read(() => {
					updateToolbar();
				});
			}),
			activeEditor.registerCommand(
				CAN_UNDO_COMMAND,
				(payload) => {
					setCanUndo(payload);
					return false;
				},
				COMMAND_PRIORITY_CRITICAL
			),
			activeEditor.registerCommand(
				CAN_REDO_COMMAND,
				(payload) => {
					setCanRedo(payload);
					return false;
				},
				COMMAND_PRIORITY_CRITICAL
			)
		);
	}, [activeEditor, editor, updateToolbar]);

	const insertLink = useCallback(
		(link = null) => {
			if (!isLink) {
				editor.dispatchCommand(TOGGLE_LINK_COMMAND, link);
			} else {
				editor.dispatchCommand(TOGGLE_LINK_COMMAND, null);
			}
		},
		[editor, isLink]
	);

	const insertTable = useCallback(
		({ rows, columns }) => {
			setShowModal(false);

			if (
				isNaN(Number(+columns)) ||
				+rows === 0 ||
				+columns === 0 ||
				isNaN(Number(+rows))
			) {
				return;
			}
			editor.dispatchCommand(INSERT_TABLE_COMMAND, { rows, columns });
		},
		[editor]
	);

	return (
		<>
			<Modal
				show={showModal}
				title={"Insert Table"}
				onClose={() => setShowModal(false)}
			>
				<TableModal
					onCancel={() => setShowModal(false)}
					onSubmit={insertTable}
				/>
			</Modal>

			<Modal show={insertLinkModal} onClose={() => setInsertLinkModal(false)}>
				<InsertLinkModal
					onCancel={() => setInsertLinkModal(false)}
					save={insertLink}
				/>
			</Modal>

			<div className="LexicalEditor__toolbar border-b-1  border-n150">
				<button
					disabled={!canUndo || !isEditable}
					onClick={() => {
						activeEditor.dispatchCommand(UNDO_COMMAND, undefined);
					}}
					title="Undo"
					type="button"
					className="toolbar-item spaced"
					aria-label="Undo"
				>
					<i className="format undo" />
				</button>
				<button
					disabled={!canRedo || !isEditable}
					onClick={() => {
						activeEditor.dispatchCommand(REDO_COMMAND, undefined);
					}}
					title="Redo"
					type="button"
					className="toolbar-item"
					aria-label="Redo"
				>
					<i className="format redo" />
				</button>
				<Divider />
				{blockType in blockTypeToBlockName && activeEditor === editor && (
					<>
						<BlockFormatDropDown
							disabled={!isEditable}
							blockType={blockType}
							editor={editor}
							hideQuoteAndCode={hideQuoteAndCode}
						/>
						<Divider />
					</>
				)}

				<button
					disabled={!isEditable}
					onClick={() => {
						activeEditor.dispatchCommand(FORMAT_TEXT_COMMAND, "bold");
					}}
					className={"toolbar-item spaced " + (isBold ? "active" : "")}
					title="Bold"
					type="button"
					aria-label={`Format text as bold.`}
				>
					<i className="format bold" />
				</button>
				<button
					disabled={!isEditable}
					onClick={() => {
						activeEditor.dispatchCommand(FORMAT_TEXT_COMMAND, "italic");
					}}
					className={"toolbar-item spaced " + (isItalic ? "active" : "")}
					title="Italic"
					type="button"
					aria-label={`Format text as italics.`}
				>
					<i className="format italic" />
				</button>
				<button
					disabled={!isEditable}
					onClick={() => {
						activeEditor.dispatchCommand(FORMAT_TEXT_COMMAND, "underline");
					}}
					className={"toolbar-item spaced " + (isUnderline ? "active" : "")}
					title="Underline"
					type="button"
					aria-label={`Format text to underlined.`}
				>
					<i className="format underline" />
				</button>

				<button
					disabled={!isEditable}
					//onClick={insertLink}
					onClick={() => {
						isLink ? insertLink() : setInsertLinkModal(true);
					}}
					className={"toolbar-item spaced " + (isLink ? "active" : "")}
					aria-label="Insert link"
					title="Insert link"
					type="button"
				>
					<i className="format link" />
				</button>
				{!hideTableAndImage && (
					<>
						<Divider />
						<button
							type="button"
							onClick={() => setShowModal(true)}
							className={"toolbar-item spaced"}
						>
							<i className="LexicalEditor__icon table" />
							<span className="text">Table</span>
						</button>

						<InsertImage activeEditor={activeEditor} />

						<InsertVideo activeEditor={activeEditor} />
						{/* <button
							onClick={() => {
								editor.dispatchCommand(INSERT_YOUTUBE_COMMAND, FillURL());
							}}
							className={"toolbar-item spaced "}
						>
							<span className="text">Insert YouTube Video</span>
						</button> */}
					</>
				)}

				<Divider />
				<DropDown
					disabled={!isEditable}
					buttonLabel="Align"
					buttonIconClassName="LexicalEditor__icon left-align"
					buttonClassName="toolbar-item spaced alignment"
					buttonAriaLabel="Formatting options for text alignment"
				>
					<DropDownItem
						onClick={() => {
							activeEditor.dispatchCommand(FORMAT_ELEMENT_COMMAND, "left");
						}}
						className="item"
					>
						<i className="LexicalEditor__icon left-align" />
						<span className="LexicalEditor__text text">Left Align</span>
					</DropDownItem>
					<DropDownItem
						onClick={() => {
							activeEditor.dispatchCommand(FORMAT_ELEMENT_COMMAND, "center");
						}}
						className="item"
					>
						<i className="LexicalEditor__icon center-align" />
						<span className="LexicalEditor__text text">Center Align</span>
					</DropDownItem>
					<DropDownItem
						onClick={() => {
							activeEditor.dispatchCommand(FORMAT_ELEMENT_COMMAND, "right");
						}}
						className="item"
					>
						<i className="LexicalEditor__icon right-align" />
						<span className="LexicalEditor__text text">Right Align</span>
					</DropDownItem>
					<DropDownItem
						onClick={() => {
							activeEditor.dispatchCommand(FORMAT_ELEMENT_COMMAND, "justify");
						}}
						className="item"
					>
						<i className="LexicalEditor__icon justify-align" />
						<span className="LexicalEditor__text text">Justify Align</span>
					</DropDownItem>

					{!hideQuoteAndCode && (
						<>
							<Divider />
							<DropDownItem
								onClick={() => {
									activeEditor.dispatchCommand(
										OUTDENT_CONTENT_COMMAND,
										undefined
									);
								}}
								className="item"
							>
								<i
									className={
										"LexicalEditor__icon " + (isRTL ? "indent" : "outdent")
									}
								/>
								<span className="LexicalEditor__text text">Outdent</span>
							</DropDownItem>
							<DropDownItem
								onClick={() => {
									activeEditor.dispatchCommand(
										INDENT_CONTENT_COMMAND,
										undefined
									);
								}}
								className="item"
							>
								<i
									className={
										"LexicalEditor__icon " + (isRTL ? "outdent" : "indent")
									}
								/>
								<span className="LexicalEditor__text text">Indent</span>
							</DropDownItem>
						</>
					)}
				</DropDown>
			</div>
		</>
	);
};

export default Toolbar;

import React, { useMemo } from "react";
import { connect } from "react-redux";
import CustomTabs from "../CustomTabs/CustomTabs";
import "./specificationContent.scss";
import SpecificationDisciplineContent from "./parts/SpecificationDisciplineContent";

const SpecificationContent = ({
	editView = false,
	jobView = true,
	defaultSelected = null,
	initialSelected,
	editable = false,
	isForPackage = false,
	updateSelected,
	customError = false,
	customErrorLabel = "Error",
	// From state
	disciplines,
}) => {
	let tabs = [];

	if (disciplines) {
		tabs = disciplines
			.map((discipline) => discipline.toJS())
			.map((discipline, index) => {
				return {
					id: index,
					label: discipline.type,
					content: () => (
						<SpecificationDisciplineContent
							editView={editView}
							isForPackage={isForPackage}
							initialSelected={initialSelected}
							updateSelected={updateSelected}
							defaultSelected={defaultSelected}
							editable={editable}
							disciplineId={discipline.id}
							jobView={jobView}
							disciplineComponentCategories={
								discipline.disciplineComponentCategories
							}
						/>
					),
				};
			});
	}

	const wrapperClassesAppBar = useMemo(() => {
		return isForPackage ? "" : "px-24";
	}, [isForPackage]);

	return (
		<div className="d-flex">
			<div className="col col-100">
				<CustomTabs
					tabsClasses={"mb-0"}
					tabs={tabs}
					wrapperClassesAppBar={wrapperClassesAppBar}
				/>
			</div>
			{customError && (
				<div className="col col-100">
					<span className={`form__error`}>{customErrorLabel}</span>
				</div>
			)}
		</div>
	);
};

const MapStateToProps = (state) => {
	let disciplines = state.getIn(["administration", "disciplines"]);

	return {
		disciplines,
	};
};

export default connect(MapStateToProps, {})(SpecificationContent);

import { api } from "../api";
import { getToken } from "../utils/local-storage.util";
import { manualConstants } from "../constants/manualConstant";
import localize, { LocKeys } from "../constants/localizations";
import { change, destroy } from "redux-form";
import { statusConstants } from "../constants/constants";
import { getManualFilters, getPagination } from "../utils/table-filter";

/**
 * Retrieves all manuals
 *
 * SUCCESS:
 *    1. Populates manual.manualList with a manual array
 *    2. Populates manual.manualMeta with the response meta info (limit, total, offset, page)
 *
 *
 * @param {object} filters - searchQuery, pageIndex = 1, limit, statuses
 *
 * @returns array of manuals
 */
export const getManuals = ({
	searchQuery = "",
	page = 1,
	limit,
	statuses,
	updateStatus = false,
	companyIds = [],
	jobStatusId = null,
}) => {
	return (dispatch, getState) => {
		let queryPath = null;
		const state = getState();

		const tableFilters = state.getIn(["form", "tableFilters", "values"]);
		const tablePagination = state.getIn(["form", "tablePagination", "values"]);

		const { status, search } = getManualFilters({
			tableFilters,
			statuses,
			searchQuery,
			updateStatus,
		});
		const { currentPage, currentLimit } = getPagination({
			tablePagination,
			page,
			limit,
		});
		const searchPath = search ? `search=${search}&` : ``;
		const pagePath = currentPage ? `page=${currentPage}&` : ``;
		const limitPath = currentLimit ? `limit=${currentLimit}&` : ``;
		const statusesPath = status ? `statuses=${JSON.stringify([status])}&` : ``;
		const companyIdsPath =
			companyIds.length > 0 ? `companyIds=${JSON.stringify(companyIds)}&` : ``;
		const jobStatusIdPath = jobStatusId ? `jobStatusId=${jobStatusId}&` : ``;

		queryPath = `${pagePath}${limitPath}${searchPath}${statusesPath}${companyIdsPath}${jobStatusIdPath}`;

		const requestOptions = {
			method: "GET",
			url: `/manual?${queryPath || ""}`,
			headers: {
				Authorization: "Bearer " + getToken(),
			},
		};
		return api(requestOptions).then(
			(res) => {
				dispatch({
					type: manualConstants.GET_MANUALS,
					data: res.data,
				});
			},
			(err) => {
				dispatch(manualActionFailure(err.response.data.result));
			}
		);
	};
};

/**
 * Used to retrieve a single manual based on the provided id
 *
 * SUCCESS: Populates 'manual' with the response job object
 *
 * ERROR: Triggers manualActionFailure()
 *
 * @param {number} id -  manual unique identifier
 * @param {boolean} prefillJobTemp - when true tempManual will be prefilled with response values
 * @returns
 */
export const getManual = (id, prefillManualTemp = false) => {
	return (dispatch) => {
		const requestOptions = {
			method: "GET",
			url: `/manual/${id}`,
			headers: {
				Authorization: "Bearer " + getToken(),
			},
		};
		return api(requestOptions).then(
			(res) => {
				dispatch({
					type: manualConstants.GET_MANUAL,
					data: res.data.result,
				});

				if (prefillManualTemp) {
					dispatch(change("editManualForm", "title", res.data.result?.title));

					dispatch({
						type: manualConstants.SET_TEMP_MANUAL,
						data: res.data.result,
					});
				}
			},
			(err) => {
				dispatch(manualActionFailure(err.response.data.result));
			}
		);
	};
};

/**
 * Edit Manual endpoint. Used to change the data of a single manual.
 *
 * @param {*} id -  Unique manual identifier
 * @param {*} data - Contains all manual form data in a key-value pair
 * @returns
 */
export const editManual = (id, data) => {
	return (dispatch) => {
		const requestOptions = {
			method: "PUT",
			url: `/manual/${id}`,
			headers: {
				Authorization: "Bearer " + getToken(),
			},
			data,
		};
		return api(requestOptions).then(
			(res) => {
				dispatch(clearTempManual("editManualForm"));
				dispatch(getManual(id, true));
				dispatch(
					manualActionSuccess(
						localize(LocKeys.ITEM_UPDATED, [localize(LocKeys.MANUAL)])
					)
				);
			},
			(err) => {
				dispatch(manualActionFailure(err.response.data.result));
			}
		);
	};
};

/**
 * Create Manual
 *
 * SUCCESS:
 * 		1. manualActionSuccess
 * 		2. clearTempManual
 *
 * @param {*} data - Contains all manuals form data in a key-value pair
 */
export const createManual = (data) => {
	return (dispatch) => {
		const requestOptions = {
			method: "POST",
			url: "/manual",
			headers: {
				Authorization: "Bearer " + getToken(),
			},
			data,
		};
		return api(requestOptions).then(
			(res) => {
				dispatch(
					manualActionSuccess(
						localize(LocKeys.ITEM_CREATED, [localize(LocKeys.MANUAL)])
					)
				);
				dispatch(clearTempManual());
			},
			(err) => {
				dispatch(manualActionFailure(err.response.data.result));
			}
		);
	};
};

/**
 * Update manual status (single & mutlipple manuals)
 *
 * SUCCESS:
 * 		1. manualActionSuccess
 * 		2. getManuals
 *
 * @param {Array<number>} ids -  offer manuals identifier
 * @param {Array<string>} status - manual status
 * @returns
 */
export const updateManualStatus = (ids, status) => {
	return (dispatch) => {
		const isArchived = status === statusConstants.ARCHIVED;
		const requestOptions = {
			method: "PUT",
			url: isArchived ? "/manual-archive" : `/manual-activate`,
			headers: {
				"Content-Type": "application/json",
				Accept: "*/*",
				Authorization: "Bearer " + getToken(),
			},
			data: { ids },
		};
		return api(requestOptions).then(
			() => {
				switch (status) {
					case statusConstants.ARCHIVED:
						dispatch(
							manualActionSuccess(
								localize(LocKeys.ITEM_ARCHIVE_SUCCESS, [
									localize(LocKeys.MANUAL),
								])
							)
						);

						break;

					default:
						dispatch(
							manualActionSuccess(
								localize(LocKeys.ITEM_ACTIVE_SUCCESS, [
									localize(LocKeys.MANUAL),
								])
							)
						);
						break;
				}

				dispatch(getManuals({ updateStatus: true }));
			},

			(err) => {
				dispatch(manualActionFailure(err.response.data.result));
			}
		);
	};
};

//---------------------------------END ENDPOINTS----------------------------------------------

//---------------------------------MANUAL STATE FUNCTION-------------------------------------

export const setTempManualContent = (data) => {
	return (dispatch) => {
		dispatch({
			type: manualConstants.SET_TEMP_MANUAL_CONTENT,
			data,
		});
	};
};

export const updateTempFieldManual = (fieldKey, value) => {
	return (dispatch) => {
		dispatch({
			type: manualConstants.UPDATE_TEMP_FIELD_MANUAL,
			data: { fieldKey, value },
		});
	};
};

export const manualActionSuccess = (data) => {
	return {
		type: manualConstants.MANUAL_ACTION_GET_SUCCESS,
		data,
	};
};

export const manualActionFailure = (data) => {
	return {
		type: manualConstants.MANUAL_ACTION_GET_FAILURE,
		data,
	};
};

export const clearSingleManual = () => {
	return (dispatch) => {
		dispatch({ type: manualConstants.CLEAR_SINGLE_MANUAL });
	};
};

export const clearTempManual = (form = "createManualForm") => {
	return (dispatch) => {
		dispatch(destroy(form));
		dispatch({ type: manualConstants.CLEAR_TEMP_MANUAL });
	};
};

export const clearManualRequestState = () => {
	return {
		type: manualConstants.CLEAR_MANUAL_REQUEST_STATE,
	};
};

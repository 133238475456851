import React, { lazy, Suspense, useEffect, useMemo, useState } from "react";
import { connect } from "react-redux";
import {
	adornmentTypeConstants,
	exportsConstants,
	sizeConstants,
} from "../../../../constants/constants";
import localize, { LocKeys } from "../../../../constants/localizations";
import EditButton from "../../../../shared/components/buttons/EditButton";
import IconWrapper from "../../../../shared/icons/IconWrapper";
import { renderNumberFormat } from "../../../../utils/number-formatter/NumberFormatter";
import OfferLabel from "../parts/OfferLabel";
import SectionHeader from "../parts/SectionHeader";
import SuspenseLoader from "../../../loader/SuspenseLoader";
import { getExportImage } from "../../../../shared/components/specification/ExportsComponent";
import { clearTempProject, getSettings } from "../../../../actions";
import { getInputFormatIcon } from "../../../../utils/getTypeIcon";
import QuestionIcon from "../../../../shared/icons/misc/QuestionIcon";
import { renderNumberModeling } from "../../../administration/pricing/modeling/helper";
import ComponentTooltip from "../../../../shared/components/component-tooltip/ComponentTooltip";
import { Link, useParams } from "react-router-dom/cjs/react-router-dom.min";
import { canEditOffer } from "./helper";

const SpecificationEdit = lazy(() => import("../edit/SpecificationEdit"));

export const renderPrice = (
	value,
	wrapperClasses = "",
	pricePerSquare = false
) => {
	return (
		<div className="d-flex">
			{+value >= 0 || value < 0 ? renderNumberFormat(value, 2) : "-"}&nbsp;
			{
				<div className={wrapperClasses}>
					&euro;
					{pricePerSquare ? (
						<>
							/m<sup>2</sup>
						</>
					) : (
						""
					)}
				</div>
			}
		</div>
	);
};

/**
 * VIEW Company Specification Info (delivery days, delivery format, price, exports, buildings)
 * @param {*} props
 * @returns
 */
const SpecificationView = (props) => {
	const {
		//State
		clientDiscount,
		vatPercentage,
		priceExclVat,
		vatPrice,
		totalPrice,
		revitVersion,
		deliveryDays,
		clearTempProject,
		buildings,
		status,
		buildingTotalSize,
		buildingTotalPrice,
		orderExportsPrice,
		orderExports,
		getSettings,
	} = props;
	const { orderId } = useParams();
	const [editView, setEditView] = useState(false);
	const canEdit = canEditOffer(status);

	useEffect(() => {
		getSettings();
	}, [getSettings]);

	const squareAppend = () => {
		return (
			<>
				&nbsp;
				<div className="color-n300">
					m<sup>2</sup>
				</div>
			</>
		);
	};

	const priceExclVatBeforeDiscount = useMemo(
		() => +buildingTotalPrice + orderExportsPrice,
		[buildingTotalPrice, orderExportsPrice]
	);

	const clientDiscountPrice = useMemo(
		() =>
			+priceExclVatBeforeDiscount -
			+priceExclVatBeforeDiscount * (1 + +clientDiscount / 100),
		[priceExclVatBeforeDiscount, clientDiscount]
	);

	const renderPriceSummary = useMemo(() => {
		return (
			<div className="row row--6p-gutters mt-48 justify-content-end">
				<div className="col col-35 flex-column">
					<div className="row row--6p-gutters align-items-center pb-m">
						<div className="col col-50 color-n300 justify-content-end">
							{localize(LocKeys.SUBTOTAL)}
						</div>

						<div className="col col-50 justify-content-end">
							<h4>{renderPrice(priceExclVatBeforeDiscount, "color-n500")}</h4>
						</div>
					</div>
					<div className="row row--6p-gutters align-items-center pb-m">
						<div className="col col-50 color-n300 justify-content-end">
							{localize(LocKeys.DISCOUNT)}&nbsp;(
							{renderNumberModeling({
								value: clientDiscount,
								toFixed: 0,
								type: adornmentTypeConstants.PERCENTAGE,
							})}
							)
						</div>

						<div className="col col-50 justify-content-end">
							<h4>{renderPrice(clientDiscountPrice, "color-n500")}</h4>
						</div>
					</div>
					<div className="row row--6p-gutters align-items-center pb-m">
						<div className="col col-50 color-n300 justify-content-end">
							{localize(LocKeys.PRICE_EXCL_VAT_LABEL)}
						</div>

						<div className="col col-50 justify-content-end">
							<h4>{renderPrice(priceExclVat, "color-n500")}</h4>
						</div>
					</div>
					<div className="row row--6p-gutters align-items-center border-b-1 border-n150 pb-m">
						<div className="col col-50 color-n300 justify-content-end">
							{localize(LocKeys.VAT_LABEL)}&nbsp;(
							{renderNumberModeling({
								value: vatPercentage,
								toFixed: 0,
								type: adornmentTypeConstants.PERCENTAGE,
							})}
							)
						</div>

						<div className="col col-50 justify-content-end">
							<h4>{renderPrice(vatPrice, "color-n500")}</h4>
						</div>
					</div>
					<div className="row row--6p-gutters align-items-center py-m">
						<div className="col col-50 color-n300 justify-content-end">
							<h3> {localize(LocKeys.TOTAL)}</h3>
						</div>

						<div className="col col-50 justify-content-end">
							<h3>{renderPrice(totalPrice, "color-n500")}</h3>
						</div>
					</div>
				</div>
			</div>
		);
	}, [
		vatPrice,
		totalPrice,
		priceExclVatBeforeDiscount,
		clientDiscountPrice,
		priceExclVat,
		vatPercentage,
		clientDiscount,
	]);

	return (
		<div
			className={`  ${
				!editView
					? " "
					: "bg-primary--lighter  border-b-1  border-t-1 border-p300"
			}`}
		>
			{!editView ? (
				<>
					<div className={`py-24 px-48 bg-n100  border-b-1 border-n150`}>
						<div className="d-flex align-items-center justify-content-between mb-regular">
							<SectionHeader title={localize(LocKeys.SUMMARY_LABEL)} />
							{canEdit && (
								<EditButton
									onClick={() => setEditView(!editView)}
									wrapperClasses="ml-auto"
								/>
							)}
						</div>

						<div className="row row--6p-gutters">
							<div className="col flex-column col-50 col-desktop-33">
								<OfferLabel
									className="mb-xs"
									label={localize(LocKeys.DELIVERY_FORMAT)}
									value={revitVersion}
								/>
							</div>

							<div className="col flex-column col-50 col-desktop-33">
								<OfferLabel
									className="mb-xs"
									label={localize(LocKeys.DELIVERY)}
									value={
										(deliveryDays ? deliveryDays : "-") +
										" " +
										localize(LocKeys.DAYS)
									}
								/>
							</div>

							{/* <div className="col flex-column col-50 col-desktop-20">
								<OfferLabel
									className="mb-xs"
									label={localize(LocKeys.PRICE_EXCL_VAT_LABEL)}
									value={renderPrice(priceExclVat)}
								/>
							</div>
							<div className="col flex-column col-50 col-desktop-20">
								<OfferLabel
									className="mb-xs"
									label={localize(LocKeys.VAT_LABEL)}
									value={renderPrice(vatPrice)}
								/>
							</div> */}
							<div className="col flex-column col-50 col-desktop-33">
								<OfferLabel
									className="mb-xs"
									label={localize(LocKeys.PRICE_INCL_VAT_LABEL)}
									value={renderPrice(totalPrice)}
								/>
							</div>
						</div>
					</div>
					{/*---------------------------------BUILDINGS-------------------------- */}
					<div className="bg-n000 p-48">
						<SectionHeader
							title={localize(LocKeys.MODELS)}
							wrapperClasses="mb-regular"
						/>
						<div className="border-b-1 border-n150">
							<div className="row row--6p-gutters pt-m pb-s">
								<div className="col col-18">
									<h5 className="small color-n300">
										{localize(LocKeys.BUILDING_NAME)}
									</h5>
								</div>
								<div className="col col-16">
									<h5 className="small color-n300">
										{localize(LocKeys.BUILDING_TYPE)}
									</h5>
								</div>
								<div className="col col-14">
									<h5 className="small color-n300">
										{localize(LocKeys.INPUT_FORMAT)}
									</h5>
								</div>
								<div className="col col-12 justify-content-end">
									<h5 className="small color-n300">
										{localize(LocKeys.BUILDING_SIZE)}
									</h5>
								</div>
								<div className="col col-8 justify-content-end">
									<h5 className="small color-n300  pr-xs">
										{localize(LocKeys.LEVELS)}
									</h5>
								</div>
								<div className="col col-12 justify-content-end">
									<ComponentTooltip
										content={localize(LocKeys.UNIQUE_LEVELS_EXPLANATION)}
										direction={"top"}
									>
										<div className="d-flex align-items-start justify-content-end">
											<h5
												className={`text-overflow-ellipsis  small color-n300 mr-xs`}
											>
												{localize(LocKeys.UNIQUE_LEVELS)}
											</h5>

											<IconWrapper
												size={sizeConstants.M}
												icon={
													<QuestionIcon iconClass="d-flex icon--target-fill fill--n300" />
												}
											/>
										</div>
									</ComponentTooltip>
								</div>
								<div className="col col-10 justify-content-end">
									<h5 className="small color-n300">
										{localize(LocKeys.PRICE)} / m<sup>2</sup>
									</h5>
								</div>
								<div className="col col-10 justify-content-end">
									<h5 className="small color-n300">
										{localize(LocKeys.TOTAL_PRICE)}
									</h5>
								</div>
							</div>
						</div>
						{buildings &&
							buildings.map((building) => {
								const inputFormatType = building.getIn(["inputFormat", "type"]);

								return (
									<div
										key={building.get("id")}
										className="border-b-1 border-n150"
									>
										<div className="row row--6p-gutters py-m  align-items-center color-n300">
											<div className="col  col-18 align-items-center color-n500">
												<ComponentTooltip
													content={building.get("name")}
													direction={"top"}
												>
													<Link
														className="no-decoration"
														to={`/offers/${orderId}/delivery/${building.get(
															"id"
														)}`}
													>
														<div className="text-overflow-ellipsis">
															{building.get("name")}
														</div>{" "}
													</Link>
												</ComponentTooltip>
											</div>
											<div className="col col-16">
												{building.getIn(["type", "type"])}
											</div>
											<div className="col col-14 align-items-center">
												{getInputFormatIcon(inputFormatType)}
												<span className="ml-s">{inputFormatType}</span>
											</div>
											<div className="col col-12 justify-content-end">
												<div className="d-flex align-items-center">
													{renderNumberFormat(building.get("size"))}
													{squareAppend()}
												</div>
											</div>
											<div className="col col-8 justify-content-end">
												<div className="pr-xs">
													{building.get("levelsTotal")
														? building.get("levelsTotal")
														: "-"}
												</div>
											</div>
											<div className="col col-12 justify-content-end">
												<div className="pr-xs">
													{building.get("uniqueLevels")
														? building.get("uniqueLevels")
														: "-"}
												</div>
											</div>
											<div className="col col-10 justify-content-end">
												{renderPrice(
													building.get("customSystemAdjustedPricePerSquare") ||
														building.get("systemAdjustedPricePerSquare") ||
														building.get("customPricePerSquare") ||
														building.get("pricePerSquare"),
													"color-n300",
													true
												)}
											</div>
											<div className="col col-10 justify-content-end">
												{renderPrice(building.get("price"), "color-n300")}
											</div>
										</div>
									</div>
								);
							})}
						<div className="row row--6p-gutters align-items-center py-m">
							<div className="col col-20 color-n500">
								<h4>{localize(LocKeys.TOTAL)}</h4>
							</div>

							<div className="col col-40 justify-content-end">
								<h4>
									{renderNumberModeling({
										value: buildingTotalSize,
										toFixed: 0,
										type: adornmentTypeConstants.SQUARE_METERS,
										typeClassName: "color-n500",
									})}
								</h4>
							</div>

							<div className="col col-40 justify-content-end">
								<h4>{renderPrice(buildingTotalPrice, "color-n500")}</h4>
							</div>
						</div>
						{/*---------------------------------EXPORTS-------------------------- */}
						<SectionHeader
							title={localize(LocKeys.EXPORTS)}
							wrapperClasses="mb-regular mt-24"
						/>
						<div className="row row--6p-gutters  align-items-center  pb-s pt-m border-b-1 border-n150">
							<div className="col  col-87-5 align-items-center">
								<h5 className="small color-n300">{localize(LocKeys.EXPORT)}</h5>
							</div>
							<div className="col  col-12-5 justify-content-end">
								<h5 className="small color-n300">{localize(LocKeys.PRICE)}</h5>
							</div>
						</div>
						{orderExports &&
							orderExports.map((orderExport, i) => {
								const tempExport = orderExport.get("export");
								const type = tempExport?.get("parent")
									? tempExport?.getIn(["parent", "type"])
									: tempExport.get("type");

								const isIFC = type === exportsConstants.IFC;
								const isObject =
									typeof orderExport.get("customPrice") === "object";
								return (
									<div
										key={i}
										className="row row--6p-gutters py-m  align-items-center border-b-1 ml-36  border-n150"
									>
										<div className="col  col-87-5 align-items-center color-n300">
											<div className="mr-m">
												<IconWrapper
													size={24}
													icon={getExportImage(type, false)}
												/>
											</div>
											<div className="color-n500">{type}</div>
											{isIFC && (
												<div className="body-font--xs ml-30">
													{tempExport.get("type")}
												</div>
											)}
										</div>
										<div className="col  col-12-5 align-items-center justify-content-end color-n300">
											{renderPrice(
												isObject
													? orderExport.get("price")
													: orderExport.get("customPrice"),
												"color-n300"
											)}
										</div>
									</div>
								);
							})}
						<div className="row row--6p-gutters align-items-center py-m">
							<div className="col col-75 color-n500">
								<h4>{localize(LocKeys.TOTAL)}</h4>
							</div>

							<div className="col col-25 justify-content-end">
								<h4>
									{buildings && renderPrice(orderExportsPrice, "color-n500")}
								</h4>
							</div>
						</div>

						{renderPriceSummary}
					</div>

					{/*---------------------------------Specification summary-------------------------- */}
				</>
			) : (
				<Suspense fallback={<SuspenseLoader />}>
					<SpecificationEdit
						onClose={() => {
							clearTempProject({
								form: "editOfferInfoForm",
								canSaveAdditionalInfo: true,
							});
							setEditView(!editView);
						}}
					/>
				</Suspense>
			)}
		</div>
	);
};
const mapStateToProps = (state) => {
	const minOfferPrice = state.getIn([
		"administration",
		"settings",
		"minOrderPrice",
	]);
	const revitVersion = state
		.getIn(["project", "project", "orderDeliveryFormats"])
		?.get(0)
		.getIn(["deliveryFormat", "type"]);
	const buildings = state.getIn(["project", "project", "buildings"]);
	const orderExports = state.getIn(["project", "project", "orderExports"]);
	const vatPrice = state.getIn(["project", "project", "vatPrice"]);
	const totalPrice = state.getIn(["project", "project", "totalPrice"]);
	const clientDiscount =
		state.getIn(["project", "project", "clientDiscount"]) || 0;

	let buildingTotalPrice = 0;
	let buildingTotalSize = 0;
	let orderExportsPrice = 0;
	let priceExclVat = +totalPrice - +vatPrice;

	buildings &&
		buildings.forEach((building) => {
			buildingTotalPrice += +building.get("price");
			buildingTotalSize += +building.get("size");
		});

	orderExports &&
		orderExports.forEach((orderExport) => {
			orderExportsPrice +=
				typeof orderExport.get("customPrice") === "object"
					? +orderExport.get("price")
					: +orderExport.get("customPrice");
		});

	return {
		revitVersion,
		buildings,
		orderExports,
		clientDiscount,
		vatPercentage: state.getIn(["project", "project", "vatPercentage"]),
		vatPrice: vatPrice,
		totalPrice: totalPrice,
		deliveryDays: state.getIn(["project", "project", "deliveryDays"]),
		status: state.getIn(["project", "project", "status"]),
		priceExclVat: priceExclVat || null,
		buildingTotalPrice:
			buildingTotalPrice < +minOfferPrice
				? +buildingTotalPrice === 0
					? buildingTotalPrice
					: +minOfferPrice
				: buildingTotalPrice,
		buildingTotalSize,
		orderExportsPrice,
	};
};

export default connect(mapStateToProps, { clearTempProject, getSettings })(
	SpecificationView
);

import React from "react";

export default (props) => {
	return (
		<span className={`icon ${props.iconClass ? props.iconClass : ""}`}>
			<svg
				width="16"
				height="16"
				viewBox="0 0 16 16"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
			>
				<circle cx="8" cy="8" r="7.5" stroke="#C5CDD3" />
				<circle cx="8" cy="8" r="4" fill="#FFDEAD" />
			</svg>
		</span>
	);
};

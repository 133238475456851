import React from "react";
import localize, { LocKeys } from "../../../../../../constants/localizations";
import SectionEdit from "../../../../../layout/section/SectionEdit";
import SectionView from "../../../../../layout/section/SectionView";
import JobAdditionalInfoEdit from "../../edit/specification-view/JobAdditionalInfoEdit";
import AdditionalInfoEditor from "../../../../../orders/single-view/parts/AdditionalInfoEditor";
import { connect } from "react-redux";

const JobAdditionalInfo = (props) => {
	const {
		additionalInfo,
		isModelHistory = false,
		isServiceTypeUpdate = false,
	} = props;
	const [editView, setEditView] = React.useState(false);
	//const canEdit = canEditJob();

	if (editView) {
		return (
			<SectionEdit title={localize(LocKeys.ADDITIONAL_INFORMATION)}>
				<JobAdditionalInfoEdit
					isServiceTypeUpdate={isServiceTypeUpdate}
					isModelHistory={isModelHistory}
					onClose={() => setEditView(!editView)}
				/>
			</SectionEdit>
		);
	}
	return (
		<SectionView
			title={localize(LocKeys.ADDITIONAL_INFORMATION)}
			canEdit={true}
			handleEdit={() => setEditView(!editView)}
		>
			<AdditionalInfoEditor
				additionalInfo={additionalInfo}
				editView={false}
				forJob={true}
				wrapperEditorClasses={"bg-n000 border-radius-regular mt-regular"}
			/>
		</SectionView>
	);
};

const mapStateToProps = (state) => {
	const serviceType = state.getIn(["job", "job", "serviceType", "type"]);
	return {
		isServiceTypeUpdate: serviceType === "Update",
		additionalInfo:
			serviceType === "Update"
				? state.getIn(["job", "job", "additionalInfo"])
				: state.getIn(["job", "job", "building", "order", "additionalInfo"]) ||
					state.getIn(["job", "job", "additionalInfo"]) ||
					"",
	};
};

export default connect(mapStateToProps, {})(JobAdditionalInfo);

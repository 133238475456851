import React, { memo, useEffect, useState } from "react";
import SelectionCheckmark from "../../../components/SelectionCheckmark";
import RadioButtonLODPreviousIcon from "../../../icons/misc/RadioButtonLODPreviousIcon";
import localize, { LocKeys } from "../../../../constants/localizations";

const LodValue = ({
	disabled = true,
	label = "",
	id,
	selected,
	handleClick,
	defaultSelected = null,
}) => {
	const [selectable, setSelectable] = useState(!disabled);

	useEffect(() => {
		setSelectable(!disabled);
	}, [disabled]);

	const selectLod = (e) => {
		e?.stopPropagation();
		if (!disabled) {
			handleClick(id, label);
		}
	};

	// const renderLabel = () => {
	// 	switch (label) {
	// 		case "0":
	// 			return localize(LocKeys.NONE);
	// 		default:
	// 			return `${localize(LocKeys.LOD)} ${label}`;
	// 	}
	// };

	return (
		<>
			<div className="lod-content__value">
				<div
					className={`lod-content__selector d-flex justify-content-center align-items-center
				    ${label === "0" ? "lod-content__selector--null" : ""}
				    ${selectable ? "" : "lod-content__selector--disabled"}
				    ${disabled ? "lod-content__selector--disabled" : ""}
				  `}
					disabled={disabled}
					onClick={selectLod}
					onKeyPress={selectLod}
				>
					{defaultSelected && !selected ? (
						<RadioButtonLODPreviousIcon iconClass="d-flex icon--16" />
					) : (
						<SelectionCheckmark
							selected={selected}
							radioButtonClass={`icon--16`}
						/>
					)}

					{/* <div className="pl-s pt-xxs">{renderLabel()}</div> */}
				</div>
			</div>
			{defaultSelected && !selected && (
				<span className="body-font--xs font--italic ml-regular color-n300">
					({localize(LocKeys.PREVIOUSLY_SELECTED)})
				</span>
			)}
		</>
	);
};
const arePropsEqual = (oldProps, newProps) => {
	if (oldProps.selected !== newProps.selected) {
		return false;
	}
	return true;
};
export default memo(LodValue, arePropsEqual);

import React, { useCallback, useState } from "react";
import { statusConstants } from "../../../../../../constants/constants";
import { useParams } from "react-router-dom-v5-compat";
import AdditionalInfoEditor from "../../../../../orders/single-view/parts/AdditionalInfoEditor";
import localize, { LocKeys } from "../../../../../../constants/localizations";
import FormSubmitButtons from "../../../../../../shared/components/FormComponents/FormSubmitButtons";
import {
	clearJob,
	updateOrder,
	setTempAdditionalInfo,
	getJob,
	updateJob,
} from "../../../../../../actions";
import { connect } from "react-redux";
import { reduxForm } from "redux-form";

const JobAdditionalInfoEdit = ({ ...props }) => {
	const { jobId } = useParams();
	const {
		isServiceTypeUpdate = false,
		isModelHistory = false,
		onClose,
		offerId,
		tempAdditionalInfo,
		additionalInfo,
		//Actions
		updateOrder,
		setTempAdditionalInfo,
		getJob,
		clearJob,
		updateJob,
	} = props;
	const [loading, setLoading] = useState(false);

	const onSubmit = () => {
		try {
			setLoading(true);
			let data = {
				additionalInfo: tempAdditionalInfo,
				status: statusConstants.REQUESTED,
			};

			if (isServiceTypeUpdate) {
				updateJob({
					jobId,
					data,
					step: 1,
					cb: () => {
						setLoading(false);
						onClose && onClose();
						clearJob();
					},
					isModelHistory,
				});
			} else {
				updateOrder({
					orderId: offerId,
					data,
					cb: () => {
						onClose && onClose();
						clearJob();
						setLoading(false);
						getJob(jobId, false, 1);
					},
					isJobView: true,
				});
			}
		} catch (e) {
			return;
		}
	};

	const handleClose = () => {
		onClose();
	};

	const updateEditorState = useCallback(
		(value) => {
			setTempAdditionalInfo(value);
		},
		[setTempAdditionalInfo]
	);

	return (
		<form
			className={`form d-flex flex-column flex-auto standardized-form`}
			onSubmit={props.handleSubmit(onSubmit)}
			autoComplete="off"
			noValidate
		>
			<AdditionalInfoEditor
				placeholder={`${localize(
					LocKeys.ADDITIONAL_INFORMATION_PLACEHOLDER
				)}...`}
				additionalInfo={additionalInfo}
				hideTableAndImage={true}
				updateEditorState={updateEditorState}
				editView={true}
				forJob={true}
				wrapperEditorClasses={`bg-n000 border-radius-regular`}
			/>

			<FormSubmitButtons
				wrapperClasses="py-24 mt-24"
				onCancel={handleClose}
				submitDisabled={loading}
				loading={loading}
			/>
		</form>
	);
};

const mapStateToProps = (state) => {
	return {
		tempAdditionalInfo: state.getIn(["projectTemp", "additionalInfo"]),
		offerId: state.getIn(["job", "job", "building", "order", "id"]),
	};
};

export default connect(mapStateToProps, {
	clearJob,
	updateOrder,
	setTempAdditionalInfo,
	getJob,
	updateJob,
})(
	reduxForm({
		form: "jobAdditionalInfoForm",
		touchOnBlur: false,
		destroyOnUnmount: false,
		enableReinitialize: true,
	})(JobAdditionalInfoEdit)
);
